<app-dmp-commons></app-dmp-commons>

<template-comment>component: layout\app.topbar.component</template-comment>
<template-comment>environment: {{ environmentName }}</template-comment>
<template-comment>is-user-admin: {{ isUserAdmin }}</template-comment>
<template-comment>is-user-device-owner: {{ isUserDeviceOwner }}</template-comment>

<div class="layout-topbar">

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">
        <span>Safe Lock Mobile</span>
    </a>



    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

        <p-chip styleClass="pl-0 pr-3">
            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">
                {{ userNameLetter }}
            </span>
            <span class="ml-2 font-medium">
                {{ userName }}
            </span>
        </p-chip>



        <button
         class="p-link layout-topbar-button"
         pTooltip="Sign Out" 
         tooltipPosition="bottom" 
         (click)="onSignOut()"
         >
            <i class="pi pi-sign-out"></i>
            <span>Sign Out</span>
        </button>

    </div>
</div>