import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { ImageModule } from 'primeng/image';
import { DataViewModule } from 'primeng/dataview';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmationService } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';
import { MessagesModule } from 'primeng/messages';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpComponent } from './dmp/components/sign-up/sign-up.component';
import { SignInComponent } from './dmp/components/sign-in/sign-in.component';
import { MessageModalComponent } from './dmp/components/message-modal/message-modal.component';
import { HomeComponent } from './dmp/components/home/home.component';
import {Amplify} from 'aws-amplify';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import amplifyconfig from '../amplifyconfiguration.json';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CountryService } from './dmp/services/country.service';

import { ToggleButtonModule } from 'primeng/togglebutton';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';

import { ProgressBarModule } from 'primeng/progressbar';

import { ToastModule } from 'primeng/toast';
import { SliderModule } from 'primeng/slider';

import { SplitButtonModule } from 'primeng/splitbutton';

import { SharedModule } from 'primeng/api';
import { TesteComponent } from './dmp/components/teste/teste.component';
import { MobileAppInstallComponent } from './dmp/components/mobile-app-install/mobile-app-install.component';
import { MobileAppComponent } from './dmp/components/mobile-app/mobile-app.component';
import { EnterpriseComponent } from './dmp/components/enterprise/enterprise.component';
import {RouterModule} from '@angular/router';
import { AppMenuComponent } from './layout/app.menu.component'; // Import the component
import { AppMenuitemComponent } from './layout/app.menuitem.component'; // Import the directive
import { AppSidebarComponent } from './layout/app.sidebar.component'; // Import the module containing the component
import { AppTopBarComponent } from './layout/app.topbar.component'; // Import the module containing the component
import { CommonModule } from '@angular/common'; // Import CommonModule
import { AppLayoutComponent } from './layout/app.layout.component'; // Import your layout component
import { ProductService } from './dmp/services/product.service';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { SkeletonModule } from 'primeng/skeleton';
import { DmpCommonsComponent } from "./dmp/components/shared/dmp-commons/dmp-commons.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  console.log('HttpLoaderFactory returning new TranslateHttpLoader');
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}//end HttpLoaderFactory

Amplify.configure(amplifyconfig);

@NgModule({
  declarations: [
      AppTopBarComponent, // Declare the component here,
      AppSidebarComponent, // Declare the module here,
      AppMenuitemComponent, // Declare the directive here,
      AppMenuComponent, // Declare the component here,
      AppComponent,
      SignUpComponent,
      SignInComponent,
      HomeComponent,
      MessageModalComponent,
      TesteComponent,
      MobileAppInstallComponent,
      MobileAppComponent,
      AppLayoutComponent
  ],
  imports: [
    AvatarModule,
    BadgeModule,
    DividerModule,
    TagModule,
    ChipModule,
    ToolbarModule,
    SkeletonModule,
    CarouselModule,
    GalleriaModule,
    ImageModule,
    CommonModule,
    RouterModule,
    SharedModule,
    ButtonModule,
    SplitButtonModule,
    AmplifyAuthenticatorModule,
    AppRoutingModule,
    MessagesModule,
    FieldsetModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    TableModule,
    HttpClientModule,
    InputTextModule,
    DialogModule,
    DataViewModule,
    TableModule,
    ConfirmDialogModule,
    RatingModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    DropdownModule,
    ButtonModule,
    ButtonModule,
    SliderModule,
    ToggleButtonModule,
    RippleModule,
    ChipsModule,
    MultiSelectModule,
    ProgressBarModule,
    ToastModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    DmpCommonsComponent
],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    [ConfirmationService],
    CountryService,
    ProductService,
    TranslateService
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class AppModule { 


}