<app-dmp-commons></app-dmp-commons>

<ng-container *ngIf="! dmpCommons.isAuthenticated()">
    Not authenticated! <a routerLink="/sign-in">Sign In</a>
    <amplify-authenticator></amplify-authenticator>
</ng-container>

<ng-container *ngIf="dmpCommons.isAuthenticated()">
    <!-- Only render this if there's an authenticated user -->

    <p-toolbar>
        <!-- p-toolbar-group-start -->
        <!-- p-toolbar-group-center -->
        <!-- p-toolbar-group-end -->

        <div class="p-toolbar-group-start" >
            <p-button
            [disabled]="mustHideButtonDisableApp()" 
            pTooltip="Disable app. It can be reactivated preserving current data" 
            tooltipPosition="bottom" 
            icon="pi pi-times" 
            styleClass="p-button-danger" 
            [label]="this.labelDisableApp"
            (click)="handleClickDisableApp($event)">
            </p-button>
        </div>

        <div class="p-toolbar-group-start" >
            <p-button
             pTooltip="Reactivate app" 
             tooltipPosition="bottom" 
             [disabled]="mustHideButtonReenableApp()"
             icon="pi pi-times"
             styleClass="p-button-info"
             [label]="this.labelReenableApp"
             (click)="handleClickReenableApp($event)">
            </p-button>
        </div>
            
        <div class="p-toolbar-group-start" >
            <p-button
            pTooltip="Remove app. It will be uninstalled, so the app data will be removed as well" 
            tooltipPosition="bottom" 
            [disabled]="mustHideButtonUninstallApp()"
            icon="pi pi-times"
            styleClass="p-button-danger"
            [label]="this.labelUninstallApp"
            (click)="handleClickUninstallApp($event)">
        </p-button>
        </div>

        <div class="p-toolbar-group-start" >
            <p-button
            pTooltip="Remove all protected app" 
            tooltipPosition="bottom" 
            [disabled]="mustHideButtonRemoveAllApps()"
            icon="pi pi-times"
            styleClass="p-button-danger"
            [label]="this.labelRemoveAllApps"
            (click)="handleClickRemoveAllApps($event)">
        </p-button>
        </div>

        <div class="p-toolbar-group-start" >
            <p-button
            pTooltip="Search for new app to install" 
            tooltipPosition="bottom" 
            icon="pi pi-plus"
            styleClass="p-button-info"
            label="New App"
            (click)="handleClickAddApp($event)">
        </p-button>
        </div>

        <div class="p-toolbar-group-end" >
            <p-button
            pTooltip="Refresh app list"
            tooltipPosition="bottom" 
            icon="pi pi-refresh"
            (click)="handleClickRefresh($event)"
            alt="Refresh Table">
            </p-button>
        </div>
        
    </p-toolbar>


    <p-toolbar [ngStyle]="{display: messagesVisibility}">
        <p-messages key="permanentMessages"></p-messages>
        <p-toast  key="shortMessages"></p-toast>
    </p-toolbar>

    <p>

    </p>

    <p-table 
    #table
    [paginator]="true"
    [rows]="10"
    [value]="apps" 
    selectionMode="single" 
    dataKey="packageName" 
    [(selection)]="selectedAppId"
    [tableStyle]="{'min-width': '8rem'}"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)=onRowUnselect($event)
    styleClass="p-datatable-gridlines"
    [rowHover]="true"
    [globalFilterFields]="['displayName','packageName']"
    >

        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <div class="table-title">
                    Protected Apps on Device
                    &nbsp; &nbsp; &nbsp;
                </div>

                <!--
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText
                    [value]="searchText"
                    placeholder="Filter App Name"
                    #filter
                    (input)="onGlobalFilter(table, $event)" />
                </span>
                -->

                <div class="input-container">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                    </span>
                    
                    <input pInputText
                    style="width: 260px;"
                    [maxLength]="60"
                    [value]="searchText"
                    placeholder="Filter App Name"
                    #filter
                    (input)="onGlobalFilter(table, $event)" />
                    
                    <span class="p-input-icon-right" *ngIf="searchText">
                        <i class="pi pi-times-circle" (click)="clearSelection()"></i>
                    </span>
                </div>


            </div>

        </ng-template>

        <ng-template pTemplate="header">
            <tr>

                <th>state</th>
                <th>disabled?</th>
                <th>displayName</th>
                <th>packageName</th>
                <th>userFacingType</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-app let-rowIndex="rowIndex">
            <tr [pSelectableRow]="app" [pSelectableRowIndex]="rowIndex">
                <td>{{app.state}}</td>
                <td>{{app.disabled}}</td>
                <td>{{app.displayName}}</td>
                <td>{{app.packageName}}</td>
                <td>{{app.userFacingType}}</td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">{{emptyTableMessage}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                {{ (apps && apps.length > 0) ? (apps.length + " item(s) found on device")  : ""  }} 
            </div>
        </ng-template>
    </p-table>


    <p>

    </p>


</ng-container>