import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { DmpCommonsComponent } from '../dmp/components/shared/dmp-commons/dmp-commons.component';
import { environment } from '../../app/../environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [DmpCommonsComponent]

})
export class AppTopBarComponent {
    environmentName = environment.environmentName;

    userName: string = '...';
    userNameLetter: string = '.';
    isUserAdmin: string = '.';
    isUserDeviceOwner: string = '.';

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router:Router, 
        public layoutService: LayoutService,
        public dmpCommons: DmpCommonsComponent

    ) { 


    }//end constructor

    ngOnInit(): void {
        if (!this.dmpCommons.isAuthenticated()) {
            console.log('app.topbar.component: not authenticated');
            return;
        }
        this.userName = this.dmpCommons.userName(); //webtecbr.suporte&#64;gmail.com
        this.userNameLetter = this.userName.charAt(0).toUpperCase();
        this.dmpCommons.hasRolePlatformAdmin().then((result: boolean) => {
            this.isUserAdmin = result.toString();
            //console.log('hasRolePlatformAdmin() result: ' + result);
        });

        this.dmpCommons.hasRoleDeviceOwner().then((result: boolean) => {
            this.isUserDeviceOwner = result.toString();

        });

    }//end ngInit

    onSignOut() {
        console.log('onSignOut()');
        this.dmpCommons.signOut();
        //this.router.navigate(['/sign-in']);
    }//end onSignOut
                    

}//end class
