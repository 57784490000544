import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { ConfirmationService, Message, MessageService, SharedModule } from 'primeng/api';

import { fetchAuthSession } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../../../../aws-exports';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-dmp-commons',
  standalone: true,
  imports: [],
  templateUrl: './dmp-commons.component.html',
  styleUrl: './dmp-commons.component.css',
  providers: [ConfirmationService, AuthenticatorService]
})

export class DmpCommonsComponent implements OnInit {

  userGroupsCache: string[] = [];

  constructor
  ( 
  public authenticator: AuthenticatorService,
  public router: Router,
  private activatedRoute: ActivatedRoute
  )
  { 
    console.log("DmpCommonsComponent.constructor()..amplify.configure(...)");
    Amplify.configure(awsExports);
  }//end constructor

  async initialize() {
    await this.loadUserGroupsPromise();
  }

  async ngOnInit(): Promise<void> {
    await this.initialize();
    console.log("DmpCommonsComponent.ngOnInit()..initialize()");
    if (!this.isAuthenticated()) {
      console.log('dmp-commons.component: not authenticated, navigating to sign-in');
      this.router.navigate(['/sign-in']);
    }//end if
  }//end ngOnInit

  getDefaultEnterpriseName(): string {
    //to-do: Return from service ( get-enterprise-according-to-user-email ) and cache locally
    return "LC03bwh3dx";
  }

  getUrnSelectedEnterpriseName(): string {
    return `urn:user:${this.userName}:selected:enterprise:`;
  }//end getUrnSelected

  getUrnSelectedDeviceName(): string {
    return `urn:user:${this.userName}:selected:device:`;
  }//end getUrnSelected
  
  handleResponse(response: any, successMessageSummary: string | undefined, successMessageDetail: string | undefined, usePermanentMessage: boolean | undefined) {
    console.info(`handleResponse response: ${response}`);
    let messageKey = "undeshortMessagesfined";
    if (usePermanentMessage) {
      messageKey = 'permanentMessages';
    }
    //this.messageService.add({ key: 'permanentMessages', severity: 'error', detail: "TESTE TESTE" });
    if (response.error && response.error.length > 0) {
      response.error.forEach((msg: any) => {
        console.error(`handleResponse error: ${msg}`);
        //this.messageService.add({ key: messageKey, severity: 'error', detail: msg });
      });
    }else{
      if (response.warn && response.warn.length > 0) {
        response.warn.forEach((msg: any) => {
          console.warn(`handleResponse warn: ${msg}`);
          //this.messageService.add({ key: messageKey, severity: 'warn', summary: msg });
        });
      } else {
        if (successMessageSummary!=undefined) {
          //this.messageService.add({ key: messageKey, severity: 'success', summary: successMessageSummary, detail: successMessageDetail });
        }

        if (response.info && response.info.length > 0) {
          response.warn.forEach((msg: any) => {
            console.info(`handleResponse info: ${msg}`);
            //this.messageService.add({ key: messageKey, severity: 'info', summary: msg });
          });
        }
      }
    }//end error handling
  }//end handleResponse

  responseHasError(response: any): boolean {
    return (response.error && response.error.length > 0);
  }//end responseHasError

  responseHasWarn(response: any): boolean {
    return (response.warn && response.warn.length > 0);
  }//end responseHasWarn

  responseHasInfo(response: any): boolean {
    return (response.info && response.info.length > 0);
  }//end responseHasInfo

  isEmptyString(value: any): boolean {
    if (value == "" || value == null || value == undefined || value == "null" || value == "undefined" || value == "blank") {
      return true;
    }

    return false;
  }//end isEmptyString

  formatDatetime(originalDatetimeStr: string): string {
    if (this.isEmptyString(originalDatetimeStr)) {
      return "";
    }

    let newDatetimeStr = originalDatetimeStr;

    try {
      const dateObj = new Date(originalDatetimeStr);
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const userLocale = navigator.language; // Get the current language and culture from the browser
  
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: userTimezone
      };

      newDatetimeStr = new Intl.DateTimeFormat(userLocale, options).format(dateObj);
    } catch (error) {
      newDatetimeStr = originalDatetimeStr;
    }

    return newDatetimeStr;
  }

  handleResponseWithMessage(
    response: any,
    successMessageSummary: string | undefined, 
    successMessageDetail: string | undefined, 
    usePermanentMessage: boolean, 
    targetMessageService: MessageService
  ) {
    let responseHasError: boolean = this.responseHasError(response);
    //usePermanentMessage = true;

    console.info(`handleResponse response: ${response}`);
    let messageKey = "undeshortMessagesfined";
    //console.log(`handleResponseWithMessage: usePermanentMessage: ${usePermanentMessage}`);
    if (usePermanentMessage) {
      //console.log("handleResponseWithMessage: usePermanentMessage true");
      messageKey = "permanentMessages";
    }else{
      //console.log("handleResponseWithMessage: usePermanentMessage false");
    }
    
    if (responseHasError) {
      response.error.forEach((msg: any) => {
        console.error(`handleResponse error: ${msg}`);
        targetMessageService.add({ key: messageKey, severity: 'error', detail: msg });
      });
    }else{
      if (this.responseHasWarn(response)) {
        response.warn.forEach((msg: any) => {
          console.warn(`handleResponse warn: ${msg}`);
          targetMessageService.add({ key: messageKey, severity: 'warn', summary: msg });
        });
      } else {
        if (successMessageSummary!=undefined) {
          targetMessageService.add({ key: messageKey, severity: 'success', summary: successMessageSummary, detail: successMessageDetail });
        }

        if (this.responseHasInfo(response)) {
          response.info.forEach((msg: any) => {
            console.info(`handleResponse info: ${msg}`);
            targetMessageService.add({ key: messageKey, severity: 'info', summary: msg });
          });
        }
      }
    }//end error handling

    return responseHasError;
  }//end handleResponse

  async hasRolePlatformAdmin(): Promise<boolean> {
    if (!this.isAuthenticated()) return false;
    
    return new Promise((resolve, reject) => {

      if (this.userGroupsCache.length > 0) {
        console.log("userGroupsCache already loaded: " + this.userGroupsCache);
      }else{
        console.log("userGroupsCache not loaded: " + this.userGroupsCache);
      }

      this.loadUserGroupsPromise().then((localUserGroups: string[]) => {
        //console.log("hasRoleDeviceOwner: localUserGroups: " + localUserGroups);
        //console.log("localUserGroups: " + localUserGroups);

        let isPlatformAdmin = false;
        isPlatformAdmin = localUserGroups.includes("platform-admin");
        console.log("hasRolePlatformAdmin: isPlatformAdmin: " + isPlatformAdmin);
        resolve(isPlatformAdmin);
      }).catch((error) => {
        console.error("hasRolePlatformAdmin: error: " + error);
        reject(false);
      });
    });
  }//end hasRoleDeviceOwner
  
  async hasRoleDeviceOwner(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loadUserGroupsPromise().then((localUserGroups: string[]) => {

        //console.log("hasRoleDeviceOwner: localUserGroups: " + localUserGroups);
        //console.log("localUserGroups: " + localUserGroups);

        let isDeviceOwner = false;
        isDeviceOwner = localUserGroups.includes("device-owner");
        resolve(isDeviceOwner);
      }).catch((error) => {
        //console.error("hasRoleDeviceOwner: error: " + error);
        reject(false);
      });
    });
  }//end hasRoleDeviceOwner

  static async userSession() {
        
    try {
        let authSessionTokens = (await fetchAuthSession()).tokens ?? {};

        return authSessionTokens;
    } catch (err) {
        console.error("userSession() error: " + err);
    }
    
    return undefined;
  }//end userSession

  static async userSessionIdToken(): Promise<string> {
    
    let idToken:string = "";

    await DmpCommonsComponent.userSession().then((authSessionTokens: any) => {
      idToken = authSessionTokens.idToken.toString();
      // console.log("userSessionIdToken().idToken: " + idToken);
      }
    )

    return idToken;

  }//end userSessionIdToken

  loadUserGroupsPromise(): Promise<string[]> {
    if (!this.isAuthenticated()) return Promise.resolve(['not authenticated']); 

    let localUserGroups: string[] = [];
    return new Promise((resolve, reject) => {
      // Simulate an asynchronous operation
      DmpCommonsComponent.userSession().then((authSessionTokens: any) => {
      
        authSessionTokens.accessToken.payload["cognito:groups"].forEach((group: string) => {
          console.log("loadUserGroups:group: " + group);
          localUserGroups.push(group);
        });

        this.userGroupsCache = localUserGroups;
        resolve(localUserGroups);
      })
      .catch(error => {
          //to-do: Goggle Analitycs
          console.error('Error getting user groups: ', error);
          reject(error);
      });

    });
  }//end loadUserGroupsPromise

  signOut() {
    this.authenticator.signOut();
    this.router.navigate(['/sign-in']);
  }//end signOut

  userName(): string {
    return this.authenticator.user.username;
  }//end userName

  isAuthenticated(): boolean {
    return this.authenticator.authStatus === 'authenticated';
  }//end isAuthenticated

  capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDeviceName(manufacturer: string, brand: string, model: string): string {
    if (this.isEmptyString(manufacturer) && this.isEmptyString(brand) && this.isEmptyString(model)) {
      return "Unknown device";
    }

    manufacturer = this.capitalize(manufacturer);
    brand = this.capitalize(brand);
    model = this.capitalize(model);

    let manufacturerBrand = "";
    if (manufacturer == brand) {
      manufacturerBrand = `${manufacturer}`;
    }else{
      manufacturerBrand = `${manufacturer} / ${brand}`;
    }

    let deviceName = `${manufacturerBrand} / ${model}`;

    return deviceName;
  }//end isAuthenticated

  parseBoolean(value: string): boolean {
    return value.toLowerCase() === 'true';
  }

  static loadLocalCache(key: string): any {
    return localStorage.getItem(key);
  }//end loadLocalCache

  static saveLocalCache(key: string, value: any): void {
    localStorage.setItem(key, value);
    return;
  }//end loadLocalCache

}//end class
