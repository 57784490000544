<app-dmp-commons></app-dmp-commons>

<p-messages 
[(value)]="enterpriseFilterMessages"  
[showTransitionOptions]="'500ms'" 
[hideTransitionOptions]="'500ms'" 
(onClose)="onEnterpriseFilterMessageClose($event)"
[enableService]="true"
[closable]="false"
class="filter-message"
> 
</p-messages>

<p-messages 
[(value)]="deviceFilterMessages"  
[showTransitionOptions]="'500ms'" 
[hideTransitionOptions]="'500ms'" 
(onClose)="onDeviceFilterMessageClose($event)"
[enableService]="true"
[closable]="false"
class="filter-message"
> 
</p-messages>



<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>

</ul>
