import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgModule, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TableComponentModule } from '../shared/ui/table.component'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CognitoService } from 'src/app/dmp/services/cognito.service';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { Product } from 'src/app/dmp/api/product';
import { Enterprise } from 'src/app/dmp/api/enterprise';
import { Customer, Representative } from 'src/app/dmp/api/customer';
import { EnterpriseService } from 'src/app/dmp/services/enterprise.service';
import { ConfirmationService, MessageService, SharedModule, ConfirmEventType } from 'primeng/api';
import { Table } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessagesModule } from 'primeng/messages';
import { FieldsetModule } from 'primeng/fieldset';
import { QRCodeModule } from 'angularx-qrcode';
import { environment } from '../../../../environments/environment';
import { DmpCommonsComponent } from '../shared/dmp-commons/dmp-commons.component';
import { EventSchema, MessageBrokerService } from '../../../layout/service/message.broker.service'; // Adjust the path as needed
import { table } from '@aws-amplify/ui/dist/types/theme/tokens/components/table';


interface expandedRows {
  [key: string]: boolean;
}

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css', '../shared/dmp-commons/dmp-commons.component.css'],
  providers: [MessageService, ConfirmationService, EnterpriseService, DmpCommonsComponent]

})

export class EnterpriseComponent implements OnInit {
  private readonly STORAGE_KEY_ENTERPRISE_SIGNUP_URL_NAME = "enterpriseSignupUrlName";

  environmentName = environment.environmentName;
  enterpriseSetupVisibility: string = "none";
  enterpriseSignupLink = "";
  enterpriseSignupUrlName = "";

  messagesVisibility: string = "none";
  isEnterpriseFilterVisible: boolean = false;

  //userGroups: string[] = [];
  idToken: string = "";
 
  //#region Table Properties
  emptyTableMessage: string = "";

  enterprises: Enterprise[] = [];

  //customers1: Customer[] = [];

  //customers2: Customer[] = [];

  //customers3: Customer[] = [];

  //selectedCustomers1: Customer[] = [];

  //selectedCustomer: Customer = {};

  //representatives: Representative[] = [];

  //statuses: any[] = [];

  //products: Product[] = [];

  rowGroupMetadata: any;

  expandedRows: expandedRows = {};

  activityValues: number[] = [0, 100];

  isExpanded: boolean = false;

  idFrozen: boolean = false;

  loading: boolean = true;

  @ViewChild('filter') filter!: ElementRef;
  selectedEnterpriseName: any;
  selectedEnterpriseDisplay: string = "";

  public newEnterpriseSetupQrCode: string = "";
  //#endregion

  constructor( 
  private changeDetector: ChangeDetectorRef,
  private messageBrokerService: MessageBrokerService,
  private messageService: MessageService, 
  private activatedRoute: ActivatedRoute,
  private router:Router, 
  private cognitoService: CognitoService,
  private enterpriseService: EnterpriseService,
  public dmpCommons: DmpCommonsComponent
  )
  { 
  }//end constructor 

  //callbackArguments?:string;
  enterpriseToken?:string;
  enterpriseSignupSource?:string;
  callbackId?:string;
  
  ngOnInit(): void {
    console.log("EnterpriseComponent.ngOnInit()");
    if (!this.dmpCommons.isAuthenticated()) {
      console.log("enterprise.component: not authenticated");
      return;
    }
    //console.log("this.dmpCommons.userGroups: "  + this.dmpCommons.userGroups);    

    //#region Parameters from route

    // Formato dos parametros esperados:
    // http://localhost:43999/enterprises?src=web&enterpriseToken=2222
    // Portanto o callback a se passar pra google deve ser: http://localhost:43999/enterprises?src=web

    // Access the raw query parameters
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('Raw query parameters:', params);
      if (params['enterpriseToken'] != undefined) {
        this.enterpriseToken = params['enterpriseToken'];
        console.log("param enterpriseToken: " + this.enterpriseToken);
      }

      if (params['src'] != undefined) {
        this.enterpriseSignupSource = params['src'];
        console.log("param src: " + this.enterpriseSignupSource);
      }

      if (params['callbackid'] != undefined) {
        this.callbackId = params['callbackid'];
        console.log("param callbackid: " + this.callbackId);
      }

      console.log("enterpriseToken: " + this.enterpriseToken);
      console.log("enterpriseSignupSource: " + this.enterpriseSignupSource);
      console.log("callbackId: " + this.callbackId);
    });
    //#endregion

    //#region get user groups

    this.dmpCommons.hasRoleDeviceOwner().then((isDeviceOwner: boolean) => {
      console.log("isDeviceOwner from Promise: " + isDeviceOwner);
      if (isDeviceOwner)  {
        console.log("isDeviceOwner: " + isDeviceOwner);
        this.selectedEnterpriseName = this.dmpCommons.getDefaultEnterpriseName();
        this.router.navigate(['/home/devices', this.selectedEnterpriseName]);
        return;
      }//end if (isDeviceOwner)
    }).catch((error: any) => {
      console.log("isDeviceOwnerPromise: error: " + error);
    });

    this.dmpCommons.hasRolePlatformAdmin().then((isPlatformAdmin: boolean) => {
      console.log("isPlatformAdmin from Promise: " + isPlatformAdmin);
    }).catch((error: any) => {
      console.log("isPlatformAdminPromise: error: " + error);
    });
    
    DmpCommonsComponent.userSessionIdToken().then((idToken: string) => {
      this.idToken = idToken;
    })
    
    //#endregion

    if (this.enterpriseToken != undefined) {
      //create enterprise from enterpriseToken (received from google callback)
      console.log("Criando enterprise a partir do token " + this.enterpriseToken + " caso src = web");
      DmpCommonsComponent.userSession().then((authSessionTokens: any) => {
        let idToken:string = authSessionTokens.idToken.toString();

        let localStorageValue = localStorage.getItem(this.STORAGE_KEY_ENTERPRISE_SIGNUP_URL_NAME);
        let enterpriseSignupUrlName = ((localStorageValue==null)?undefined:localStorageValue!) as string;

        this.createEnterpriseFromToken(this.callbackId as string, idToken, this.enterpriseToken, enterpriseSignupUrlName as string);

      })
      .catch(error => {
          //to-do: Goggle Analitycs
          console.error('Error creating session for enterprise creation: ', error);
          let detailsMessage = `Error creating session for enterprise creation !`;
          this.messagesVisibility = "block";
          this.messageService.add({ key: "permanentMessages", severity: 'error', detail: detailsMessage });
  
      });
      //return;
    }

    //#region service data
    this.loadTable();
    //#endregion
    
  }//end ngOnInit

  private loadTable() {
    //Implementation reference
    DmpCommonsComponent.userSessionIdToken().then((idToken: any) => {
      this.emptyTableMessage = "Loading...";

      //console.log(idToken); //to-do: remove this

      let projectId = "device-manager-webtec-corp";
      this.enterpriseService.getEnterprises(idToken, projectId).subscribe({
        next: enterprisesResponse => {
          //to-do: testar retorno de warn, err, info
          this.enterprises = enterprisesResponse.data;
          console.log("this.enterprises.length: " + this.enterprises.length);
          if (this.enterprises.length == 0) {
            this.emptyTableMessage = "No data found";
          }

          let selectedEnterpriseNameCached = DmpCommonsComponent.loadLocalCache(this.dmpCommons.getUrnSelectedEnterpriseName());
          console.log("enterprise:selectedEnterpriseNameCached: " + selectedEnterpriseNameCached);
          this.enterprises.forEach(enterprise => {
            console.log("enterprise.name: " + enterprise.name);
            if (enterprise.name == selectedEnterpriseNameCached) {
              console.log("enterprise.name == selectedEnterpriseNameCached: " + enterprise.name);
              this.selectedEnterpriseName = enterprise.name;
             
              this.rowSelectAction(enterprise);
            }
          });


        },
        error: error => {
          // Handle error if needed
          this.emptyTableMessage = "Load failed, try again later...";
          console.error('Error fetching enterprises:', error);
          this.messagesVisibility = "block";
          this.messageService.add({ key: "permanentMessages", severity: 'error', detail: "Error fetching enterprises" });
        }
      });

    }).catch(error => {
        this.emptyTableMessage = "Load failed, try again later...";
        console.error('Error fetching currentSession() to get enterprises:', error);
    });
  }//end loadTable

  //#region cognito
  forceSignOut () {
    this.dmpCommons.signOut();
    //this.router.navigate(['/sign-in']);
  }
  //#endregion

  //#region Table Methods
  onRowSelect(event: any) { 
    console.log(`onRowSelect.type: ${event.type}`);
    console.log(`onRowSelect.originalEvent: ${event.originalEvent}`);

    let enterprise = event.data
    this.rowSelectAction(enterprise);

    DmpCommonsComponent.saveLocalCache(this.dmpCommons.getUrnSelectedEnterpriseName(), enterprise.name);
  } 

  private rowSelectAction(enterprise: Enterprise) {
    let messageContent: EventSchema = new EventSchema();
    let eventValue: Enterprise = enterprise;

    messageContent.key = 'enterprise-selected';
    messageContent.value = eventValue;
    console.log("rowSelectAction: messageContent.key: " + messageContent.key);
    console.log("rowSelectAction: messageContent.value: " + messageContent.value);
    this.messageBrokerService.sendEventMessage(messageContent);

    console.log("rowSelectAction: this.selectedEnterpriseName: " + enterprise.name);
    this.selectedEnterpriseName = enterprise.name;
    this.selectedEnterpriseDisplay = `[ current: ${enterprise.name} ]`;
    
    this.isEnterpriseFilterVisible = true;

    //this.changeDetector.detectChanges();
    //console.log("this.changeDetector.detectChanges()");
  }

  rowSelectable(row: any) {
    console.log("rowSelectable: row: " + row);
    return row.enabled; // Assuming 'enabled' is a property in your data object
  }

  onButtonEnterpriseClose(event: any) {
    console.log('Button Message closed:', event);
    this.clearFilter();
    this.isEnterpriseFilterVisible = false;
    // Your custom logic here, e.g., reset the message array or perform some cleanup
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
      this.rowGroupMetadata = {};

      /*
      if (this.customers3) {
          for (let i = 0; i < this.customers3.length; i++) {
              const rowData = this.customers3[i];
              const representativeName = rowData?.representative?.name || '';

              if (i === 0) {
                  this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
              }
              else {
                  const previousRowData = this.customers3[i - 1];
                  const previousRowGroup = previousRowData?.representative?.name;
                  if (representativeName === previousRowGroup) {
                      this.rowGroupMetadata[representativeName].size++;
                  }
                  else {
                      this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
                  }
              }
          }
      }
      */
  }

  expandAll() {
      if (!this.isExpanded) {
          //this.products.forEach(product => product && product.name ? this.expandedRows[product.name] = true : '');

      } else {
          this.expandedRows = {};
      }
      this.isExpanded = !this.isExpanded;
  }

  formatCurrency(value: number) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  onGlobalFilter(table: Table, event: Event) {
      table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  clear(table: Table) {
      table.clear();
      this.filter.nativeElement.value = '';
  }

  handleClickNewEnterprise(event: any) {
    this.createEnterpriseSigninLink();
  }

  handleClickDeleteEnterprise(event: any) {
    if (this.selectedEnterpriseName == null || this.selectedEnterpriseName == undefined) {
      console.log("handleClickDevices event: enterprise is undefined: select one");
      return;
    }
    console.log("handleClickDevices event: " + this.selectedEnterpriseName);

    let enterpriseId = this.selectedEnterpriseName.replace("enterprises/", "");

    this.deleteEnterprise(enterpriseId);
  }

  handleClickDevices(event: any) {
    if (this.selectedEnterpriseName == null || this.selectedEnterpriseName == undefined) {
      console.log("handleClickDevices event: enterprise is undefined: select one");
      return;
    }
    console.log("handleClickDevices event: " + this.selectedEnterpriseName);

    let enterpriseId = this.selectedEnterpriseName.replace("enterprises/", "");

    //this.router.navigate(['/devices', enterpriseId]);
    this.router.navigate(['/home/devices', enterpriseId]);
  }

  //#endregion
  createEnterpriseSigninLink() {
    console.log("starting createEnterpriseSigninLink()");
    //this.router.navigate(['/enterprises', "web", "123"]);
    //return;
    DmpCommonsComponent.userSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();

      let projectId = "device-manager-webtec-corp";
      let source = "web"; // it can be web or mobile for example
      //let source = "backend"; // (TEMP, for testing) it can be web or mobile for example.
      this.enterpriseService.createSignupLink(idToken, projectId, source).subscribe({
        next: enterpriseSigupLinkResponse => {
          //to-do: testar retorno de warn, err, info
          console.log("enterpriseSigupLinkResponse.data: " + enterpriseSigupLinkResponse.data);
          console.log("enterpriseSigupLinkResponse.data.toString(): " + enterpriseSigupLinkResponse.data.toString());

          try {
            console.log("enterpriseSigupLinkResponse.data.signup_url_name: " + enterpriseSigupLinkResponse.data.signup_url_name);
            console.log("enterpriseSigupLinkResponse.data.enterprise_signup_link: " + enterpriseSigupLinkResponse.data.enterprise_signup_link);
          } catch (error) {
            console.log("Error readind enterpriseSigupLinkResponse.data.signup_url_name");
            console.log("Error readind enterpriseSigupLinkResponse.data.enterprise_signup_link");
          }
          
          let signup_url_name = ""
          let enterprise_signup_link = ""

          try {
            signup_url_name = enterpriseSigupLinkResponse.data.signup_url_name
            enterprise_signup_link = enterpriseSigupLinkResponse.data.enterprise_signup_link

            console.log("enterpriseSigupLinkResponse.data.signup_url_name: " + enterpriseSigupLinkResponse.data.signup_url_name);
            console.log("enterpriseSigupLinkResponse.data.enterprise_signup_link: " + enterpriseSigupLinkResponse.data.enterprise_signup_link);

          } catch (error) {
            signup_url_name = "{error:signup_url_name}"
            enterprise_signup_link = "{error:enterprise_signup_link}"
          }

          this.enterpriseSetupVisibility = "block";
          this.enterpriseSignupUrlName = signup_url_name;

          localStorage.setItem(this.STORAGE_KEY_ENTERPRISE_SIGNUP_URL_NAME, this.enterpriseSignupUrlName);
          
          this.enterpriseSignupLink = enterprise_signup_link
          //let summaryMessage = `Initial Setup Link: ${enterprise_signup_link}`;
          //let detailsMessage = `signup_url_name: ${this.enterpriseSignupUrlName}`;
          let summaryMessage = `Onboarding Link Created !`;
          let detailsMessage = `Just click the link ( or use qrcode ) provided, using the google account that will be the administrator of device(s), which can be the same account you use everyday. From there, follow instructions directly from Google.`;
          this.messagesVisibility = "block";
          this.messageService.add({ key: "permanentMessages", severity: "success", summary: summaryMessage, detail: detailsMessage });
          
          this.newEnterpriseSetupQrCode = enterprise_signup_link;

        },
        error: error => {
          // Handle error if needed
          console.error('Error fetching enterprises:', error);
        }
      });
  
    })
    .catch(error => {
        console.error('Error fetching currentSession() to get enterprises:', error);
    });
  }

  handleClickRefresh(event: any) {
    this.enterprises = [];
    this.onRowUnselect(event);
    
    setTimeout(() => {
      this.loadTable();
    }, 2000);
  }

  onRowUnselect(event: any) { 
    console.log("onRowUnselect event");

    this.clearFilter();
  } 

  clearFilter() {
    this.selectedEnterpriseName = undefined;
    this.selectedEnterpriseDisplay = ``;

    let messageContent: EventSchema = new EventSchema();
    messageContent.key = 'enterprise-unselected';
    this.messageBrokerService.sendEventMessage(messageContent);
    this.isEnterpriseFilterVisible = false;
  }

  deleteEnterprise(enterpriseId: string) {
    //this.router.navigate(['/enterprises', "web", "123"]);
    //return;
    DmpCommonsComponent.userSession().then((authSessionTokens: any) => {
      let idToken:string = authSessionTokens.idToken.toString();

      this.enterpriseService.deleteEnterprise(idToken, enterpriseId).subscribe({
        next: enterpriseResponse => {
          //On success, the response from google is empty, (http 200 on success)
        },
        error: error => {
          // Handle error if needed
          console.error('Error deleting enterprise:', error);
        }
      });
  
    })
    .catch(error => {
        console.error('Error fetching currentSession() to deleting enterprise:', error);
    });
  }

  createEnterpriseFromToken(callbackId: string, idToken: string, enterpriseToken: string | undefined, signupUrlName: string | undefined) {
    let enterprise_name = undefined;
    let projectId = "device-manager-webtec-corp";
    this.enterpriseService.createEnterprise(callbackId, idToken, projectId, enterpriseToken, signupUrlName).subscribe({
      next: enterpriseResponse => {
        //to-do: testar retorno de warn, err, info
        console.log("enterpriseResponse: ", enterpriseResponse);
        let enterprise_data = enterpriseResponse['data'];
        enterprise_name = enterprise_data['enterprise_name'];

        let summaryMessage = `Enterprise Created !`;
        let detailsMessage = `Enterprise name is {${enterprise_name}}.`;
        this.messagesVisibility = "block";
        this.messageService.add({ key: "permanentMessages", severity: "success", summary: summaryMessage, detail: detailsMessage });

      },
      error: error => {
        // Handle error if needed
        console.error('Error creating enterprise:', error);
        let detailsMessage = "Error creating enterprise";
        this.messagesVisibility = "block";
        this.messageService.add({ key: "permanentMessages", severity: 'error', detail: detailsMessage });
      }
    });
  
  }

}//end EnterpriseComponent

@NgModule({
  declarations: [EnterpriseComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    QRCodeModule,
    SharedModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    CommonModule,
    ButtonModule,
    MessagesModule,
    FieldsetModule,
    TableComponentModule,
    RouterModule.forChild([
        {
            path: '',
            component: EnterpriseComponent,
        },
    ]),
    DmpCommonsComponent
],
})

export class EnterpriseModule {}
