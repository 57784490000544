<app-dmp-commons></app-dmp-commons>

<ng-container *ngIf="! dmpCommons.isAuthenticated()">
    Not authenticated! <a routerLink="/sign-in">Sign In</a>
    <amplify-authenticator></amplify-authenticator>
</ng-container>

<ng-container *ngIf="dmpCommons.isAuthenticated()">
    <!-- Only render this if there's an authenticated user -->

    <p-toolbar [ngStyle]="{display: deviceSetupVisibility}">
        <div class="" style="vertical-align: middle;">
            <h4 class="text-left md:text-left">New Device Registration</h4>
            <div class="flex flex-column md:flex-row" style="vertical-align: middle;">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="m:0 p:0">
                        Visit this link from your Android Device: <a href="{{ deviceEnrollmentLink }}">Device Registration Link</a>
                        <br><b>Or</b> scan this QR code from another device
                    </div>
                </div>
    
                <div class="w-full md:w-1">
                    <p-divider layout="vertical" styleClass="hidden md:flex"></p-divider>
                    <p-divider layout="horizontal" styleClass="flex md:hidden" [align]="'center'"></p-divider>
                </div>
    
                <div class="">
                    <div class="qrcodeImage">
                      <!-- https://cordobo.github.io/angularx-qrcode/ -->
                      <qrcode
                        [qrdata]="newDeviceRegistrationQrCode"
                        [allowEmptyString]="true"
                        [ariaLabel]="'QR Code with the device registration link'"
                        [cssClass]="'qrcodeBorderRadius'"
                        [colorDark]="'#3f51b5'"
                        [colorLight]="'#ffffffff'"
                        [elementType]="'canvas'"
                        [errorCorrectionLevel]="'H'"
                        [margin]="4"
                        [title]="'QR Code with the device registration link'"
                        [width]="10"
                        [scale]="2"
                      ></qrcode>
                    </div>
                </div>
    
    
            </div>
        </div>

    </p-toolbar>

    <p-toolbar [ngStyle]="{display: messagesVisibility}">
        <p-messages key="permanentMessages"></p-messages>
        <p-toast key="shortMessages"></p-toast>
    </p-toolbar>


    <p-toolbar >
        <div class="p-toolbar-group-start" >
            <p-button
            pTooltip="Add a new device" 
            tooltipPosition="bottom" 
            showDelay="50"
            hideDelay="300"
            icon="pi pi-plus"
            styleClass="p-button-info"
            [disabled]="this.mustHideButtonNewRegistration()"
            [label]="this.labelNewRegistration"
            (click)="handleClickNewDevice($event)"></p-button>
        </div>

        <div class="p-toolbar-group-start" >
            
            <p-button
             pTooltip="Manage protected apps on your device" 
             tooltipPosition="bottom" 
             showDelay="50"
             hideDelay="300"
             [disabled]="mustHideButtonListApplications()"
             icon="pi pi-list"
             styleClass="p-button-success"
             label="Applications"
             (click)="handleClickApps($event)"></p-button>
        </div>

        <div class="p-toolbar-group-start" >
            
            <p-button
             [disabled]="mustHideButtonLockDevice()"
             icon="pi pi-lock"
             pTooltip="This will lock device as if the user has locked it from the device or screen timeout has occurred"
             tooltipPosition="bottom" 
             showDelay="50"
             hideDelay="300"
             styleClass="p-button-warning"
             [label]="this.labelLockDevice"
             (click)="handleClickLockDevice($event)"></p-button>
        </div>

        <div class="p-toolbar-group-start" >
            
            <p-button
              pTooltip="This will also safely remove protected apps and data" 
              tooltipPosition="bottom" 
              showDelay="50"
              hideDelay="300"
              [disabled]="mustHideButtonWipe()"
              icon="pi pi-times"
              styleClass="p-button-danger"
              [label]="this.labelWipe"
              (click)="handleClickRemoveDevice($event)"></p-button>
        </div>

        <div class="p-toolbar-group-end" >
            <p-button
             icon="pi pi-refresh"
             pTooltip="Refresh the device list" 
             tooltipPosition="bottom" 
             (click)="handleClickRefresh($event)">
            </p-button>
        </div>
    </p-toolbar>

    <p>
    
    </p>

    <p-table 
    #mainTable
    [value]="devices" 
    sortField="lastPolicySyncTime"
    [sortOrder]="-1"
    selectionMode="single" 
    [(selection)]="selectedDeviceId"
    dataKey="name" 
    [(selection)]="selectedDeviceName"
    [tableStyle]="{'min-width': '50rem'}"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    [rowHover]="true"
    >
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <div class="table-title">
                    Devices
                    &nbsp; &nbsp; &nbsp;
                </div>

            </div>
            <div class="p-toolbar-group-left">
                <p-button 
                class="filter-button"
                key="deviceFilter"
                (onClick)="onButtonFilterDeviceClose($event)"
                [hidden]="!isDeviceFilterVisible" 
                [raised]="true"
                severity="secondary" [text]="true"
                [label]="this.selectedDeviceDisplay" icon="pi pi-times" iconPos="right" />
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <ng-container *ngIf="isDeviceOwner$ | async as isDeviceOwner">
                <tr *ngIf="isDeviceOwner">
                    <th>Device Name</th>
                    <th>Creation Time</th>
                    <th>Last Sync Time</th>
                    <th>Network Operator</th>
                    <!-- <th>Custom Device Name</th> -->
                    <th>State</th>
                </tr>
            </ng-container>

            <ng-container *ngIf="isPlatformAdmin$ | async as isPlatformAdmin">
                <tr *ngIf="isPlatformAdmin">
                    <td colspan="4">[debug: isPlatformAdmin mode]</td>
                </tr>
                <tr *ngIf="isPlatformAdmin">
                    <th>deviceId</th>
                    <th>enrollmentTime</th>
                    <th>lastPolicySyncTime</th>
                    <th>lastStatusReportTime</th>

                    <th>manufacturer / brand / model</th>
                    
                    <th>state</th>
                    <th>networkOperatorName</th>

                    <th>ownership</th>
                    <th>appliedState</th>
                    <th>accountIdentifier</th>
                    <th>userName</th>
                    
                    <th>enrollmentTokenData</th>
                    <th>policyName</th>
                    <th>appliedPolicyName</th>
                    <th>appliedPolicyVersion</th>

                    <!-- <th>customDeviceName</th> -->
                    <th>emmDeviceId</th>
                    <th>isPendingRegistration</th>
                    <th>dtCreation</th>
                </tr>
            </ng-container>

        </ng-template>
        <ng-template pTemplate="body" let-device>
            <ng-container *ngIf="isDeviceOwner$ | async as isDeviceOwner">
                <tr *ngIf="isDeviceOwner" [pSelectableRow]="device">
                    <td>{{formatDeviceName(device.hardwareInfo.manufacturer, device.hardwareInfo.brand, device.hardwareInfo.model) }}</td>
                    <td>{{formatDatetime(device.dtCreation)}}</td>
                    <td>{{formatDatetime(device.lastPolicySyncTime)}}</td>
                    <td>{{formatDeviceNetworkOperator(device)}}</td>
                    <!-- <td>{{device.customDeviceName}}</td> -->
                    <td>{{formatDeviceState(device.isPendingRegistration)}}</td>
                </tr>
            </ng-container>

            <ng-container *ngIf="isPlatformAdmin$ | async as isPlatformAdmin">
                <tr *ngIf="isPlatformAdmin" [pSelectableRow]="device">
                    <td>{{device.deviceId}}</td>
                    <td>{{formatDatetime(device.enrollmentTime)}}</td>
                    <td>{{formatDatetime(device.lastPolicySyncTime)}}</td>
                    <td>{{formatDatetime(device.lastStatusReportTime)}}</td>

                    <td>{{formatDeviceName(device.hardwareInfo.manufacturer, device.hardwareInfo.brand, device.hardwareInfo.model) }}</td>
                    <td>{{device.state}}</td>
                    <td>{{formatDeviceNetworkOperator(device)}}</td>

                    <td>{{device.ownership}}</td>
                    <td>{{device.appliedState}}</td>
                    <td>{{device.user.accountIdentifier}}</td>
                    <td>{{device.userName}}</td>
                    
                    <td>{{device.enrollmentTokenData}}</td>
                    <td>{{device.policyName}}</td>
                    <td>{{device.appliedPolicyName}}</td>
                    <td>{{device.appliedPolicyVersion}}</td>
                    
                    <!-- <td>{{device.customDeviceName}}</td> -->
                    <td>{{device.emmDeviceId}}</td>

                    <td>{{device.isPendingRegistration}}</td>
                    <td>{{formatDatetime(device.dtCreation)}}</td>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="16">{{emptyTableMessage}}</td>
            </tr>
        </ng-template>
    </p-table>

    
    <p>
    
    </p>

    <p-toolbar >
        <div class="flex flex-column gap-2">
            <input 
                pInputText 
                id="newDeviceExtraParams" 
                aria-describedby="newdevice-extraparams-help" 
                [(ngModel)]="this.newDeviceExtraParams" />
            <small id="username-help">
                Testing: Enter your comma separated extra params to register new device
            </small>
        </div>
    </p-toolbar>



    <p-dialog [(visible)]="showLockDialog" [style]="{width: '450px'}" header="Lock device" [modal]="true" class="p-fluid">
    
        <ng-template pTemplate="content">
            
            <div class="field">
                <p>
                <label for="name">Create a Password ( Optional ). </label>
                </p>
                <p>
                <label for="name">The password will be asked only for PROTECTED APPS ( IN WORK PROFILE ONLY ). </label>
                </p>
                <p>
                <label for="name">The main device password is unchanged. </label>
                </p>
                <p>
                <input type="password" pInputPassword id="name" [(ngModel)]="newLockPassword" required autofocus />
                </p>
                <p>
                <input type="password" pInputPassword id="name" [(ngModel)]="newLockPasswordVerification" required autofocus />
                </p>
            </div>

        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="handleClickCancelLockDialog($event)"></button>
            <button pButton pRipple label="Lock" icon="pi pi-check" class="p-button-text" (click)="handleClickConfirmLockDialog($event)"></button>
        </ng-template>

    </p-dialog>

</ng-container>